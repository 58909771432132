.badge {
	display: inline-block;
	padding: 4px 16px;
	height: 29px;

	border-radius: 20px;
	background: #06b260;

	font-weight: 400;
	font-size: 14px;
	line-height: 150%;
	text-align: center;
	color: #fff;

	&--small {
		padding: 1px 8px;
		height: 20px;
		font-size: 12px;
	}

	&--bold {
		font-weight: 700;
	}

	&--gray {
		background: #444;
	}

	&--yellow {
		color: #000;
		// background-color: #ffec49;
		background-color: #ffd931;
	}
}
