.mobile-cover {
	display: none;
	border-radius: 20px;
	object-fit: cover;

	width: 100%;
	height: auto;

	@include mobile {
		display: block;
	}
}
