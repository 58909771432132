.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.8);

	// display: none;
	opacity: 0;
	pointer-events: none;
	transition: opacity 0.2s ease-in;

	&.modal--open {
		// display: flex;
		opacity: 1;
		pointer-events: all;
	}
}

.modal__body {
	position: relative;
	width: 420px;

	padding: 40px;
	border-radius: 20px;
	background-color: #fff;

	display: flex;
	flex-direction: column;
	gap: 20px;

	transform: translate(0%, -50%);
	transition: transform 0.2s ease-in-out;

	@include mobile {
		width: 340px;
		padding: 20px;
	}
}

.modal--open .modal__body {
	transform: translate(0%, 0%);
}

.modal__close {
	position: absolute;
	top: 14px;
	right: 14px;

	@include mobile {
		top: 8px;
		right: 8px;
	}
}

.modal__header {
	display: flex;
	flex-direction: column;
	gap: 10px;

	text-align: center;
}

.modal__title {
	font-size: 36px;
	font-weight: 800;
	line-height: 1.2;

	@include mobile {
		font-size: 28px;
	}
}

.modal__text {
	font-size: 14px;
	line-height: 1.5;
}

.modal__inputs {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.input {
	padding: 20px 30px;
	height: 60px;
	border: 1px solid #b3b3b3;
	border-radius: 80px;
	background: #fff;

	font-size: 16px;
	line-height: 1.2;
	color: #000;
}

.modal__checkbox-label {
	display: flex;
	align-items: center;
	gap: 10px;
	font-size: 12px;
	color: #2f2f2f;
}

.btn-modal {
	display: block;
	padding: 10px 20px;
	border-radius: 80px;
	background-color: #000;
	font-weight: 700;
	color: #fff !important;
	transition: background-color 0.2s ease-in;

	&:hover {
		background-color: #333;
	}
}

// Checkbox

/* Custom Checkboxes */

.fake-checkbox {
	position: relative;

	width: 20px;
	height: 20px;
	flex-shrink: 0;

	border: 1px solid #999999;
	background: #fff;
	border-radius: 4px;

	transition: all 0.2s ease-in;
}

.fake-checkbox::after {
	content: '';

	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%) scale(0);
	transition: all 0.2s ease-in;

	width: 16px;
	height: 13px;

	background-image: url('./../img/icons/tick.svg');
	// background-image: url("data:image/svg+xml,%3Csvg width='16' height='13' viewBox='0 0 16 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.06055 5.93933L7.06055 11.9393M4.93923 11.9393L14.9392 1.93933' stroke='%2337B6CC' stroke-width='3'/%3E%3C/svg%3E%0A");
	background-repeat: no-repeat;
	background-position: center;
}

.real-checkbox:checked + .fake-checkbox {
	background-color: #000;
	border: 1px solid #000;
}

.real-checkbox:checked + .fake-checkbox::after {
	transform: translate(-50%, -50%) scale(1);
}
