.icons-wrapper {
	padding: 30px 0;
	display: flex;
	column-gap: 30px;
}

.icon {
	fill: transparent;
	stroke: transparent;
	width: 62px;
	height: 62px;
}

.icon--logo {
	fill: var(--logo-color);
	width: 176px;
	height: 25px;

	// use {
	// 	height: 26px;
	// }
}

.icon--youtube {
	fill: #90a3bd;
	transition: all 0.2s ease-in;
	stroke-width: 4px;

	&:hover {
		stroke: rgb(17, 193, 90);
	}
}

.svg-YouTube-dims {
	width: 82px;
	height: 60px;
}
