.logo {
	flex-shrink: 0;
}

.logo__img {
	margin-bottom: 2px;
	height: 26px;
}

.logo__text {
	display: block;

	font-weight: 400;
	font-size: 12px;
	line-height: 130%;
	color: #95b2d1;
}
