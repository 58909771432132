@import url(https://fonts.googleapis.com/css?family=Montserrat:100,200,300,regular,500,600,700,800,900,100italic,200italic,300italic,italic,500italic,600italic,700italic,800italic,900italic);

/* Base */
@import './base/reset';
@import './base/vars';
@import './base/mixins';
@import './base/fontsAutoGen';
@import './base/base';
@import './base/docs'; /* Отключить при необходимости */
@import './base/utils'; // Тест на ошибку
@import './base/containers';
@import './base/sticky-footer';

/* Blocks */
@import "blocks/_badge.scss";
@import "blocks/_btns.scss";
@import "blocks/_callback.scss";
@import "blocks/_course.scss";
@import "blocks/_dark-mode-btn.scss";
@import "blocks/_detail.scss";
@import "blocks/_footer.scss";
@import "blocks/_free-course.scss";
@import "blocks/_header.scss";
@import "blocks/_hero.scss";
@import "blocks/_icons.scss";
@import "blocks/_intensive.scss";
@import "blocks/_laptop copy.scss";
@import "blocks/_laptop.scss";
@import "blocks/_learn.scss";
@import "blocks/_logo-mobile.scss";
@import "blocks/_logo.scss";
@import "blocks/_main.scss";
@import "blocks/_mobile-cover.scss";
@import "blocks/_mobile-nav.scss";
@import "blocks/_modal.scss";
@import "blocks/_nav-icon.scss";
@import "blocks/_nav.scss";
@import "blocks/_plate.scss";
@import "blocks/_post.scss";
@import "blocks/_section-text.scss";
@import "blocks/_section-title.scss";
@import "blocks/_section.scss";
@import "blocks/_start.scss";
@import "blocks/_tag.scss";
@import "blocks/_tech.scss";
@import "blocks/_titles.scss";
@import "blocks/_webinar.scss";

/* No styles code below. Only in modules */
/* Не пишите CSS код ниже. Только в подключаемых файлах */
