.learn {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 30px;

	padding: 30px;

	border-radius: 20px;
	background: #f0f8ff;

	font-weight: 300;
	font-size: 16px;
	line-height: 160%;
	color: #000;

	@include mobile {
		grid-template-columns: 1fr;
		padding: 20px;
		font-size: 14px;
	}
}

.learn__desc {
}

.learn__title {
	margin-bottom: 20px;
}

.learn__list {
	padding-left: 25px;

	li {
		list-style: disc;
	}

	li + li {
		margin-top: 15px;
	}
}

.learn__plates {
	display: grid;
	grid-template-columns: 1fr 1fr;

	gap: 20px;

	&-special {
		grid-column: 1 / -1;
	}

	@include mobileSM {
		grid-template-columns: 1fr;
	}
}
