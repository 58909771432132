:root {
	--container-width: 1140px;
	--container-padding: 15px;

	--font-main: 'Montserrat', sans-serif;
	--font-accent: 'Montserrat', sans-serif;
	--font-titles: var(--font-accent);

	--page-bg: #fff;
	--text-color: #000;
	--titles-color: #000;

	--accent: #1A71D2;
	--link-color: #1A71D2;

	--laptop-size: 1199px;
	--tablet-size: 959px;
	--mobile-size: 599px;

	--border-color: #dcedf9;
	--box-border-color: #e6f1f4;
	--logo-color: #1a71d2;
}

.dark {
	--page-bg: #171717;
	--text-color: #95bcd9;
	--titles-color: #fff;

	--border-color: #262626;
	--box-border-color: #436077;

	--logo-color: #4595d4;
}

/* Social Brand Colors */

:root {
	/* Вконтакте */
	--vk-blue: #4a76a8;

	/* Телеграм */
	--telegram-blue: #0088cc;

	/* WhatsApp */
	--whatsapp-green: #25d366;

	/* Facebook */
	--facebook-blue: #1877f2;

	/* Instagram */
	--instagram-purple: #833ab4;

	/* Twitter */
	--twitter-blue: #1da1f2;

	/* LinkedIn */
	--linkedin-blue: #0077b5;

	/* Snapchat */
	--snapchat-yellow: #fffc00;

	/* Pinterest */
	--pinterest-red: #bd081c;

	/* TikTok */
	--tiktok-black: #000;

	/* YouTube */
	--youtube-red: #ff0000;
}
