
html {
	scroll-behavior: smooth;
	// background-color: rgb(39, 39, 39);
}

body {
	background-color: var(--page-bg);
	color: var(--text-color);
	font-family: var(--font-main);
	// text-wrap: balance;
}

::selection {
    color: #ffffff;
    background-color: #0088f0;
}

img {
	display: block;
}

a {
	color: var(--link-color);
}

code {
	background-color: #e9f1f6;
	padding: 0.2rem;
	border-radius: 4px;
}

pre.code {
	overflow-x: auto;
	background-color: #e9f1f6;
	padding: 1rem;
	border-radius: 4px;
}
