.title-1 {
	font-family: var(--font-titles);
	font-weight: 700;
	line-height: 120%;
	color: var(--titles-color);

	font-size: 42px;
	font-size: clamp(2rem, 1.67rem + 1.39vw, 2.625rem);
}

.title-2 {
	font-family: var(--font-titles);
	font-weight: 700;
	font-size: 32px;
	line-height: 120%;
	color: var(--titles-color);
}
