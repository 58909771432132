.header {
	padding: 30px 0;
	border-bottom: 1px solid var(--border-color);

	&--on-main {
		border-bottom: none;
	}

	&__row {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}

.header__nav {
	@include mobile {
		display: none;
	}
}

.header__btns {
	display: flex;
	gap: 30px;
	align-items: center;
}

.header__mobile-nav-btn {
	display: none;

	@include mobile {
		display: block;
	}
}