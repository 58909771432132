.footer-iframe {
	width: 100%;
	height: 1055px;
	display: block;
}

@media (min-width: 590px) {
	.footer-iframe {
		width: 100%;
		height: 697px;
	}
}

@media (min-width: 992px) {
	.footer-iframe {
		width: 100%;
		height: 422px;
	}
}

// .footer {
// 	background-color: rgb(39, 39, 39);
// 	padding: 50px 0;
// 	font-size: 32px;
//     color: #fff;

// 	h1 {
// 		font-size: 32px;
// 	}

// 	a {
// 		// color: var(--link-color);
// 		color: #fff;
// 		text-decoration: underline;
// 	}

// 	@media (max-width: 1200px) {
// 		font-size: 26px;
// 	}
// }

// .footer__copyright {
// 	padding: 10px 0;
// 	font-size: 16px;

// }
