@mixin mediaBg() {
	@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
		@content;
	}
}

@mixin tablet() {
	@media (max-width: 1220px) {
		@content;
	}
}

@mixin mobile() {
	@media (max-width: 991px) {
		@content;
	}
}

@mixin mobileMD() {
	@media (max-width: 670px) {
		@content;
	}
}

@mixin mobileSM() {
	@media (max-width: 520px) {
		@content;
	}
}
