.tag {
	display: inline-flex;
	justify-content: center;
	align-items: center;

	border: 1px solid #b6cce4;
	border-radius: 8px;
	padding: 12px 20px;

	font-weight: 400;
	font-size: 14px;
	line-height: 130%;
	color: #000;

	@include mobile {
		font-size: 12px;
		padding: 6px 10px;
		border-radius: 4px;
	}
}
