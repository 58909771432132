.start {
	border: 1px solid var(--box-border-color);
	border-radius: 20px;
	padding: 30px;

	display: flex;
	flex-direction: column;
	gap: 20px;

	font-weight: 300;
	font-size: 16px;
	line-height: 160%;

	p + p {
		margin-top: 0.5em;
	}

	@include mobile {
		font-size: 14px;
		padding: 20px;
	}
}

.start__badge {
	margin-bottom: -10px;
}

.start__list {
	padding-left: 25px;
	li {
		list-style: disc;
	}

	li + li {
		margin-top: 0.5em;
	}
}

.start__tags {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	gap: 10px;
}
