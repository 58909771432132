.detail {
	display: flex;
	align-items: center;
	column-gap: 5px;
}

.detail__icon {
}

.detail__text {
	font-weight: 300;
	font-size: 14px;
	line-height: 100%;
	color: #1587d6;
}
