@charset "UTF-8";
@import url(https://fonts.googleapis.com/css?family=Montserrat:100,200,300,regular,500,600,700,800,900,100italic,200italic,300italic,italic,500italic,600italic,700italic,800italic,900italic);
/* Base */
/* Reset and base styles  */
* {
  padding: 0px;
  margin: 0px;
  border: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Links */
a, a:link, a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

/* Common */
aside, nav, footer, header, section, main {
  display: block;
}

h1, h2, h3, h4, h5, h6, p {
  font-size: inherit;
  font-weight: inherit;
}

ul, ul li {
  list-style: none;
}

img {
  vertical-align: top;
}

img, svg {
  max-width: 100%;
  height: auto;
}

address {
  font-style: normal;
}

/* Form */
input, textarea, button, select {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  background-color: transparent;
}

input::-ms-clear {
  display: none;
}

button, input[type=submit] {
  display: inline-block;
  box-shadow: none;
  background-color: transparent;
  background: none;
  cursor: pointer;
}

input:focus, input:active,
button:focus, button:active {
  outline: none;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

label {
  cursor: pointer;
}

legend {
  display: block;
}

:root {
  --container-width: 1140px;
  --container-padding: 15px;
  --font-main: "Montserrat", sans-serif;
  --font-accent: "Montserrat", sans-serif;
  --font-titles: var(--font-accent);
  --page-bg: #fff;
  --text-color: #000;
  --titles-color: #000;
  --accent: #1A71D2;
  --link-color: #1A71D2;
  --laptop-size: 1199px;
  --tablet-size: 959px;
  --mobile-size: 599px;
  --border-color: #dcedf9;
  --box-border-color: #e6f1f4;
  --logo-color: #1a71d2;
}

.dark {
  --page-bg: #171717;
  --text-color: #95bcd9;
  --titles-color: #fff;
  --border-color: #262626;
  --box-border-color: #436077;
  --logo-color: #4595d4;
}

/* Social Brand Colors */
:root {
  /* Вконтакте */
  --vk-blue: #4a76a8;
  /* Телеграм */
  --telegram-blue: #0088cc;
  /* WhatsApp */
  --whatsapp-green: #25d366;
  /* Facebook */
  --facebook-blue: #1877f2;
  /* Instagram */
  --instagram-purple: #833ab4;
  /* Twitter */
  --twitter-blue: #1da1f2;
  /* LinkedIn */
  --linkedin-blue: #0077b5;
  /* Snapchat */
  --snapchat-yellow: #fffc00;
  /* Pinterest */
  --pinterest-red: #bd081c;
  /* TikTok */
  --tiktok-black: #000;
  /* YouTube */
  --youtube-red: #ff0000;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: var(--page-bg);
  color: var(--text-color);
  font-family: var(--font-main);
}

::selection {
  color: #ffffff;
  background-color: #0088f0;
}

img {
  display: block;
}

a {
  color: var(--link-color);
}

code {
  background-color: #e9f1f6;
  padding: 0.2rem;
  border-radius: 4px;
}

pre.code {
  overflow-x: auto;
  background-color: #e9f1f6;
  padding: 1rem;
  border-radius: 4px;
}

.docs {
  display: grid;
  line-height: 1.5;
}
.docs p {
  margin: 1rem 0;
}
.docs ul,
.docs ol {
  padding-left: 2rem;
}
.docs ul li,
.docs ol li {
  list-style: disc;
  margin-bottom: 0.5rem;
}
.docs ol li {
  list-style: decimal;
}
.docs section, .docs section.docs {
  padding: 40px 0;
}
.docs section + section {
  border-top: 1px solid #dae5e9;
}
.docs small {
  font-size: 1rem;
  color: rgb(172, 172, 172);
}
.docs .title-1:first-child,
.docs .title-2:first-child {
  margin-top: 0 !important;
}

.test {
  width: 600px;
  height: 300px;
  margin: 50px auto;
  background-color: #999;
  background-position: center center; /* x y */
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("./../img/project-02.jpg");
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .test {
    background-image: url("./../img/project-02@2x.jpg");
  }
}

.test-2 {
  width: 600px;
  height: 300px;
  margin: 50px auto;
  background-color: #999;
  background-position: center center; /* x y */
  background-size: cover;
  background-repeat: no-repeat;
  background-image: image-set(url("./../img/project-02.jpg") 1x, url("./../img/project-02@2x.jpg") 2x);
}

.font-1 {
  font-family: "Montserrat";
  font-weight: 700;
  font-style: italic;
}

.font-2 {
  font-family: "FirasansBook";
  font-weight: 400;
}

/* Отключить при необходимости */
.none {
  display: none !important;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.no-scroll {
  overflow-y: hidden;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.d-flex {
  display: flex;
}

.flex-center {
  justify-content: center;
}

/* Демо контент */
.content-demo {
  margin-bottom: 5rem;
  padding: 1rem;
  background-color: #dadada;
}

/* Контейнеры */
.container {
  margin: 0 auto;
  padding: 0 var(--container-padding);
  max-width: var(--container-width);
  width: 100%;
}

.container-full {
  padding: 0 var(--container-padding);
  max-width: 100%;
}

.container-left-50 {
  padding: 0 var(--container-padding);
  max-width: 50%;
}
@media (max-width: 991px) {
  .container-left-50 {
    max-width: 100%;
  }
}

.container-right-50 {
  padding: 0 var(--container-padding);
  max-width: 50%;
  margin-left: auto;
}
@media (max-width: 991px) {
  .container-right-50 {
    max-width: 100%;
  }
}

.container-right {
  padding-left: calc((100% - var(--container-width)) / 2 + var(--container-padding));
}
@media (max-width: 1220px) {
  .container-right {
    padding-left: var(--container-padding);
  }
}

.container-left {
  padding-right: calc((100% - var(--container-width)) / 2 + var(--container-padding));
}
@media (max-width: 1220px) {
  .container-left {
    padding-right: var(--container-padding);
  }
}

.container-half-left {
  padding-right: calc((100% - var(--container-width)) / 2 + var(--container-width) / 2);
  padding-left: calc((100% - var(--container-width)) / 2 + var(--container-padding));
}
@media (max-width: 1220px) {
  .container-half-left {
    padding-left: var(--container-padding);
  }
}
@media (max-width: 991px) {
  .container-half-left {
    padding: 0 var(--container-padding);
  }
}

.container-half-right {
  padding-left: calc((100% - var(--container-width)) / 2 + var(--container-width) / 2);
  padding-right: calc((100% - var(--container-width)) / 2 + var(--container-padding));
}
@media (max-width: 1220px) {
  .container-half-right {
    padding-right: var(--container-padding);
  }
}
@media (max-width: 991px) {
  .container-half-right {
    padding: 0 var(--container-padding);
  }
}

html, body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.footer {
  margin-top: auto;
}

.footer {
  padding: 60px 0;
  background-color: #e3e3e3;
} /* Blocks */
.badge {
  display: inline-block;
  padding: 4px 16px;
  height: 29px;
  border-radius: 20px;
  background: #06b260;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: #fff;
}
.badge--small {
  padding: 1px 8px;
  height: 20px;
  font-size: 12px;
}
.badge--bold {
  font-weight: 700;
}
.badge--gray {
  background: #444;
}
.badge--yellow {
  color: #000;
  background-color: #ffd931;
}

.btn,
a.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
  height: 45px;
  padding: 12px 30px;
  border-radius: 8px;
  background: #000;
  font-weight: 600;
  font-size: 16px;
  line-height: 130%;
  text-align: center;
  color: #fff;
  transition: background-color 0.2s ease-in, color 0.2s ease-in;
}

.btn:hover,
.btn:focus {
  background-color: var(--accent);
}

.btn:active {
  background-color: #222222;
}

.callback {
  padding: 60px 0 80px;
  background: #212121;
  color: #fff;
}
@media (max-width: 991px) {
  .callback {
    padding: 30px 0 40px;
  }
}

.callback__title {
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 28px;
  line-height: 130%;
}

.callback__desc {
  margin-bottom: 30px;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  color: rgba(255, 255, 255, 0.5);
  text-wrap: balance;
}
@media (max-width: 991px) {
  .callback__desc {
    font-size: 14px;
  }
}
.callback__desc p + p {
  margin-top: 0.5em;
}

.callback__form {
  display: flex;
  align-items: center;
  gap: 20px 30px;
  flex-wrap: wrap;
}
@media (max-width: 991px) {
  .callback__form {
    column-gap: 15px;
  }
}
@media (max-width: 670px) {
  .callback__form {
    flex-direction: column;
    align-items: stretch;
  }
  .callback__form > * {
    width: 100%;
  }
}

.callback__input {
  flex: 1;
  height: 58px;
  border-radius: 10px;
  background: #3e3e3e;
  padding: 18px 15px;
  font-weight: 400;
  font-size: 18px;
  line-height: 130%;
  color: #fff;
}
@media (max-width: 991px) {
  .callback__input {
    font-size: 16px;
  }
}

.callback__btn {
  min-width: 290px;
  flex: 1;
  height: 58px;
  border-radius: 10px;
  background: #0060cf;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  color: #fff;
}
@media (max-width: 991px) {
  .callback__btn {
    font-size: 16px;
  }
}
@media (max-width: 670px) {
  .callback__btn {
    flex: auto;
  }
}

.callback__terms {
  padding-top: 10px;
  font-size: 12px;
  color: #777;
}
.callback__terms a {
  color: #888;
  text-decoration: underline;
}

.course {
  position: relative;
  padding: 10px;
  padding-right: 30px;
  display: flex;
  align-items: stretch;
  column-gap: 25px;
  border-radius: 16px;
  background-color: #fafafa;
  transition: all 0.2s ease-in;
}
.course:hover {
  background-color: #f3faff;
}
@media (max-width: 670px) {
  .course {
    flex-direction: column;
    padding-right: 10px;
  }
}

.course__link {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
}

.course__picture {
  flex-shrink: 0;
  width: 330px;
  height: auto;
  border-radius: 16px;
  overflow: hidden;
}
@media (max-width: 991px) {
  .course__picture {
    width: 200px;
  }
}
@media (max-width: 670px) {
  .course__picture {
    width: 100%;
  }
  .course__picture img {
    width: 100%;
  }
}
.course__picture img {
  object-fit: cover;
  height: 100%;
}

.course__desc {
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 10px;
}
@media (max-width: 670px) {
  .course__desc {
    padding-bottom: 10px;
  }
}

.course__badges {
  display: flex;
  align-items: center;
  gap: 10px;
}

.course__title {
  font-weight: 600;
  font-size: 32px;
  font-size: clamp(1.375rem, 1.196rem + 0.89vw, 2rem);
  line-height: 130%;
}

.course__text {
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #000;
}
.course__text p + p {
  margin-top: 10px;
}

.course__details {
  display: flex;
  gap: 10px 20px;
  align-items: center;
  flex-wrap: wrap;
}

.dark-mode-btn {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 51px;
  height: 26px;
  padding: 5px;
  border-radius: 50px;
  background-color: #272727;
}

.dark-mode-btn::before {
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  display: block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #fff;
  transition: left 0.2s ease-in;
}

.dark-mode-btn--active::before {
  left: 26px;
}

.dark-mode-btn__icon {
  position: relative;
  z-index: 9;
}

.detail {
  display: flex;
  align-items: center;
  column-gap: 5px;
}

.detail__text {
  font-weight: 300;
  font-size: 14px;
  line-height: 100%;
  color: #1587d6;
}

.footer-iframe {
  width: 100%;
  height: 1055px;
  display: block;
}

@media (min-width: 590px) {
  .footer-iframe {
    width: 100%;
    height: 697px;
  }
}
@media (min-width: 992px) {
  .footer-iframe {
    width: 100%;
    height: 422px;
  }
}
.free-course__cover {
  --grad-1: linear-gradient(
  	180deg,
  	rgba(5, 25, 43, 0.5) 0%,
  	rgba(9, 24, 39, 0.5) 100%
  );
  --grad-2: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  height: 395px;
  padding: 60px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #fff;
  background-color: #555;
  background-image: var(--grad-1), var(--grad-2), url("./../../img/free-course/free-course-bg.jpg");
  background-position: center center; /* x y */
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 16px;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .free-course__cover {
    background-image: var(--grad-1), var(--grad-2), url("./../../img/free-course/free-course-bg@2x.jpg");
  }
}

.free-course__badge {
  margin-bottom: 10px;
}

.free-course__title {
  font-weight: 600;
  font-size: 48px;
  font-size: clamp(1.75rem, 1.393rem + 1.79vw, 3rem);
  line-height: 1.3;
  text-align: center;
}

.free-course__content {
  position: relative;
  z-index: 1;
  margin: 0 auto;
  margin-top: -184px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
  border-radius: 16px;
  max-width: 790px;
  height: 232px;
  box-shadow: 0 4px 25px 0 rgba(34, 55, 80, 0.15);
  background: #fff;
  color: #000;
  text-align: center;
}
@media (max-width: 991px) {
  .free-course__content {
    height: auto;
  }
}

.free-course__desc {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
  text-align: center;
}
.free-course__desc p + p {
  margin-top: 0.5em;
}

.free-course__details {
  display: flex;
  gap: 10px 20px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.header {
  padding: 30px 0;
  border-bottom: 1px solid var(--border-color);
}
.header--on-main {
  border-bottom: none;
}
.header__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 991px) {
  .header__nav {
    display: none;
  }
}

.header__btns {
  display: flex;
  gap: 30px;
  align-items: center;
}

.header__mobile-nav-btn {
  display: none;
}
@media (max-width: 991px) {
  .header__mobile-nav-btn {
    display: block;
  }
}

.hero {
  --grad-1: linear-gradient(
  	180deg,
  	rgba(6, 31, 54, 0.5) 0%,
  	rgba(9, 24, 39, 0.5) 100%
  );
  --grad-2: linear-gradient(
  	180deg,
  	rgba(0, 0, 0, 0) 0%,
  	rgba(0, 0, 0, 0.5) 100%
  );
  height: 614px;
  height: clamp(18.75rem, 13.143rem + 28.04vw, 38.375rem);
  padding: 30px 0;
  background-color: #555555;
  background-image: var(--grad-2), var(--grad-1), url("./../../img/header/header-bg.jpg");
  background-position: center center; /* x y */
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .hero {
    background-image: var(--grad-2), var(--grad-1), url("./../../img/header/header-bg@2x.jpg");
  }
}
.hero .container {
  height: 100%;
}

.hero__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
}

.hero__title {
  margin-bottom: 20px;
  font-weight: 700;
  font-size: clamp(1.75rem, 0.964rem + 3.93vw, 4.5rem);
  line-height: 1;
  text-align: center;
}

.hero__desc {
  margin-bottom: 40px;
  max-width: 540px;
  font-weight: 300;
  font-size: 28px;
  font-size: clamp(1.125rem, 0.946rem + 0.89vw, 1.75rem);
  line-height: 1.6;
  text-align: center;
  text-wrap: balance;
}
@media (max-width: 991px) {
  .hero__desc {
    margin-bottom: 20px;
  }
}
.hero__desc p + p {
  margin-top: 0.5em;
}

.hero__arrow {
  margin-top: -15px;
  padding: 15px;
}
@media (max-width: 991px) {
  .hero__arrow {
    margin-top: -5px;
    padding: 5px;
  }
}

.icons-wrapper {
  padding: 30px 0;
  display: flex;
  column-gap: 30px;
}

.icon {
  fill: transparent;
  stroke: transparent;
  width: 62px;
  height: 62px;
}

.icon--logo {
  fill: var(--logo-color);
  width: 176px;
  height: 25px;
}

.icon--youtube {
  fill: #90a3bd;
  transition: all 0.2s ease-in;
  stroke-width: 4px;
}
.icon--youtube:hover {
  stroke: rgb(17, 193, 90);
}

.svg-YouTube-dims {
  width: 82px;
  height: 60px;
}

.intensive {
  border-radius: 10px;
  background: #f4faff;
}

.intensive__picture {
  padding: 10px;
  height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 670px) {
  .intensive__picture {
    height: 220px;
  }
}

.intensive__desc {
  padding: 20px;
  display: flex;
  flex-direction: column;
}
@media (max-width: 670px) {
  .intensive__desc {
    padding-top: 0;
  }
}

.intensive__title {
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  color: #000;
}
@media (max-width: 670px) {
  .intensive__title {
    font-size: 16px;
  }
}

.intensive__text {
  margin-bottom: 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #000;
}

.intensive__badges {
  margin-bottom: 30px;
}

.intensive__details {
  margin-top: auto;
  display: flex;
  column-gap: 15px;
  align-items: center;
}

.laptop {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  color: #000;
  text-wrap: balance;
}
.laptop a,
.laptop button {
  text-decoration: underline;
  color: #000;
}
.laptop small {
  font-size: 12px;
}

.laptop__img-wrapper {
  position: relative;
  margin-bottom: 20px;
  flex-grow: 1;
}
@media (max-width: 991px) {
  .laptop__img-wrapper {
    margin-bottom: 0;
  }
}

.laptop__btn {
  display: none !important;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.laptop__btn svg .path2 {
  transition: all 0.2s ease-in;
}
.laptop__btn svg .path2:hover {
  fill: red;
}

.laptop__img {
  border-radius: 20px;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
@media (max-width: 991px) {
  .laptop__img {
    display: none;
  }
}

.laptop__content {
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  background-color: #fffaf0;
}
@media (max-width: 991px) {
  .laptop__content {
    background-color: #deffc4;
  }
}

.laptop__desc {
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  color: #6288b1;
}
@media (max-width: 991px) {
  .laptop__desc {
    color: #547b35;
  }
}

.learn {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  padding: 30px;
  border-radius: 20px;
  background: #f0f8ff;
  font-weight: 300;
  font-size: 16px;
  line-height: 160%;
  color: #000;
}
@media (max-width: 991px) {
  .learn {
    grid-template-columns: 1fr;
    padding: 20px;
    font-size: 14px;
  }
}

.learn__title {
  margin-bottom: 20px;
}

.learn__list {
  padding-left: 25px;
}
.learn__list li {
  list-style: disc;
}
.learn__list li + li {
  margin-top: 15px;
}

.learn__plates {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
.learn__plates-special {
  grid-column: 1/-1;
}
@media (max-width: 520px) {
  .learn__plates {
    grid-template-columns: 1fr;
  }
}

.logo-mobile {
  display: inline-block;
}

.logo-mobile__img {
  margin-bottom: 2px;
  height: 26px;
}

.logo-mobile__text {
  display: block;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  color: #95b2d1;
}

.logo {
  flex-shrink: 0;
}

.logo__img {
  margin-bottom: 2px;
  height: 26px;
}

.logo__text {
  display: block;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  color: #95b2d1;
}

.main__grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
}
@media (max-width: 991px) {
  .main__grid {
    grid-template-columns: 1fr;
  }
}

.main__learn {
  grid-column: 1/-1;
}

.mobile-cover {
  display: none;
  border-radius: 20px;
  object-fit: cover;
  width: 100%;
  height: auto;
}
@media (max-width: 991px) {
  .mobile-cover {
    display: block;
  }
}

.mobile-nav-overlay {
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100%;
  height: 100vh;
  background: rgba(37, 73, 116, 0.8);
  pointer-events: none;
  opacity: 0;
  transition: all 0.2s ease-in;
}
.mobile-nav-overlay--open {
  pointer-events: all;
  opacity: 1;
}

.mobile-nav {
  position: fixed;
  top: 0;
  right: 0;
  width: 320px;
  height: 100%;
  z-index: 99;
  display: flex;
  flex-direction: column;
  background: #fff;
  color: #000;
  transform: translate(100%, 0%);
  transition: all 0.2s ease-in;
}

.mobile-nav--open {
  transform: translate(0%, 0%);
}

.mobile-nav a {
  color: #000;
}

.mobile-nav__logo {
  padding: 40px 20px 30px 30px;
  border-bottom: 1px solid #dcedf9;
}

.mobile-nav__nav {
  padding: 30px;
}

.mobile-nav__nav + .mobile-nav__nav {
  padding-top: 0;
}

.mobile-nav__title {
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 16px;
  line-height: 130%;
  color: #698cb3;
}

.mobile-nav__list {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  font-weight: 300;
}
.mobile-nav__list li a {
  display: flex;
  align-items: center;
  column-gap: 10px;
}
.mobile-nav__list strong {
  font-weight: 600;
}

.mobile-nav__footer {
  margin-top: auto;
  border-top: 1px solid #dcedf9;
  background-color: #f6fbff;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s ease-in;
}
.modal.modal--open {
  opacity: 1;
  pointer-events: all;
}

.modal__body {
  position: relative;
  width: 420px;
  padding: 40px;
  border-radius: 20px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 20px;
  transform: translate(0%, -50%);
  transition: transform 0.2s ease-in-out;
}
@media (max-width: 991px) {
  .modal__body {
    width: 340px;
    padding: 20px;
  }
}

.modal--open .modal__body {
  transform: translate(0%, 0%);
}

.modal__close {
  position: absolute;
  top: 14px;
  right: 14px;
}
@media (max-width: 991px) {
  .modal__close {
    top: 8px;
    right: 8px;
  }
}

.modal__header {
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: center;
}

.modal__title {
  font-size: 36px;
  font-weight: 800;
  line-height: 1.2;
}
@media (max-width: 991px) {
  .modal__title {
    font-size: 28px;
  }
}

.modal__text {
  font-size: 14px;
  line-height: 1.5;
}

.modal__inputs {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.input {
  padding: 20px 30px;
  height: 60px;
  border: 1px solid #b3b3b3;
  border-radius: 80px;
  background: #fff;
  font-size: 16px;
  line-height: 1.2;
  color: #000;
}

.modal__checkbox-label {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 12px;
  color: #2f2f2f;
}

.btn-modal {
  display: block;
  padding: 10px 20px;
  border-radius: 80px;
  background-color: #000;
  font-weight: 700;
  color: #fff !important;
  transition: background-color 0.2s ease-in;
}
.btn-modal:hover {
  background-color: #333;
}

/* Custom Checkboxes */
.fake-checkbox {
  position: relative;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  border: 1px solid #999999;
  background: #fff;
  border-radius: 4px;
  transition: all 0.2s ease-in;
}

.fake-checkbox::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: all 0.2s ease-in;
  width: 16px;
  height: 13px;
  background-image: url("./../img/icons/tick.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.real-checkbox:checked + .fake-checkbox {
  background-color: #000;
  border: 1px solid #000;
}

.real-checkbox:checked + .fake-checkbox::after {
  transform: translate(-50%, -50%) scale(1);
}

/* Nav Icon */
.mobile-nav-btn {
  --time: 0.1s;
  --width: 40px;
  --height: 30px;
  --line-height: 1px;
  --spacing: 6px;
  --color: var(--titles-color);
  --radius: 0px;
  /* Fixed height and width */
  /* height: var(--height); */
  /* width: var(--width); */
  /* Dynamic height and width */
  height: calc(var(--line-height) * 3 + var(--spacing) * 2);
  width: var(--width);
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-icon {
  position: relative;
  width: var(--width);
  height: var(--line-height);
  background-color: var(--color);
  border-radius: var(--radius);
}

.nav-icon::before,
.nav-icon::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  width: var(--width);
  height: var(--line-height);
  border-radius: var(--radius);
  background-color: var(--color);
  transition: transform var(--time) ease-in, top var(--time) linear var(--time);
}

.nav-icon::before {
  /* top: calc(var(--line-height) * -2); */
  top: calc(-1 * (var(--line-height) + var(--spacing)));
}

.nav-icon::after {
  /* top: calc(var(--line-height) * 2); */
  top: calc(var(--line-height) + var(--spacing));
}

.nav-icon.nav-icon--active {
  background-color: transparent;
}

.nav-icon.nav-icon--active::before,
.nav-icon.nav-icon--active::after {
  top: 0;
  transition: top var(--time) linear, transform var(--time) ease-in var(--time);
}

.nav-icon.nav-icon--active::before {
  transform: rotate(45deg);
}

.nav-icon.nav-icon--active::after {
  transform: rotate(-45deg);
}

/* Layout */
.mobile-nav-btn {
  z-index: 999;
  padding: 15px 0;
}
.mobile-nav-btn--open {
  position: absolute;
  right: 15px;
  top: 30px;
}

.nav {
  font-weight: 300;
  font-size: 16px;
  line-height: 130%;
}
.nav a {
  color: #000;
}

.nav__list {
  display: flex;
  column-gap: 50px;
}

.plate {
  padding: 20px;
  border-radius: 12px;
  background: #d9ecfe;
  font-weight: 300;
  font-size: 14px;
  line-height: 130%;
  text-wrap: balance;
}
@media (max-width: 991px) {
  .plate {
    padding: 16px;
    font-size: 12px;
  }
}

.plate__title {
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
}

.plate--special {
  background: #fffaf0;
}
@media (max-width: 991px) {
  .plate--special {
    background-color: #deffc4;
  }
}

.post {
  display: flex;
  align-items: center;
  column-gap: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #dcedf9;
}
@media (max-width: 670px) {
  .post {
    display: grid;
    grid-template-columns: auto auto;
    gap: 10px 20px;
  }
}
.post:last-child {
  border-bottom: none;
}

.post__picture {
  flex-shrink: 0;
  overflow: hidden;
  border-radius: 10px;
  width: 60px;
  height: 60px;
}
.post__picture img {
  border-radius: 10px;
}

.post__title {
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: #000;
  max-width: 635px;
}
@media (max-width: 670px) {
  .post__title {
    font-size: 14px;
  }
}

.post__category {
  margin-left: auto;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: #7daac9;
}
@media (max-width: 670px) {
  .post__category {
    grid-column: 2/-1;
    margin-left: 0;
    font-size: 14px;
  }
}

.section-text {
  font-weight: 300;
  font-size: 16px;
  line-height: 160%;
  color: #000;
  text-wrap: balance;
}
@media (max-width: 991px) {
  .section-text {
    font-size: 14px;
  }
}
.section-text p + p {
  margin-top: 0.5em;
}

.section-title {
  font-weight: 700;
  font-size: 42px;
  font-size: clamp(1.75rem, 1.5rem + 1.25vw, 2.625rem);
  line-height: 130%;
}
@media (max-width: 991px) {
  .section-title {
    font-weight: 600;
  }
}
.section-title a {
  color: inherit;
  text-decoration: underline;
}

.section {
  padding: 90px 0;
  border-bottom: 1px solid #dcedf9;
}
@media (max-width: 1220px) {
  .section {
    padding: 60px 0;
  }
}
@media (max-width: 991px) {
  .section {
    padding: 30px 0;
  }
}

.section__badge {
  margin-bottom: 10px;
}

.section__title {
  margin-bottom: 15px;
}
@media (max-width: 991px) {
  .section__title {
    margin-bottom: 5px;
  }
}

.section__desc {
  margin-bottom: 30px;
  max-width: 730px;
}
@media (max-width: 991px) {
  .section__desc {
    margin-bottom: 15px;
  }
}

.section__wrapper--courses {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
}
.section__wrapper--intesive {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
  gap: 30px;
}
.section__wrapper--blog {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.start {
  border: 1px solid var(--box-border-color);
  border-radius: 20px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-weight: 300;
  font-size: 16px;
  line-height: 160%;
}
.start p + p {
  margin-top: 0.5em;
}
@media (max-width: 991px) {
  .start {
    font-size: 14px;
    padding: 20px;
  }
}

.start__badge {
  margin-bottom: -10px;
}

.start__list {
  padding-left: 25px;
}
.start__list li {
  list-style: disc;
}
.start__list li + li {
  margin-top: 0.5em;
}

.start__tags {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
}

.tag {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #b6cce4;
  border-radius: 8px;
  padding: 12px 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  color: #000;
}
@media (max-width: 991px) {
  .tag {
    font-size: 12px;
    padding: 6px 10px;
    border-radius: 4px;
  }
}

.tech {
  display: inline-block;
  border-radius: 3px;
  padding: 1px 5px;
  height: 20px;
  background: #7daac9;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  color: #fff;
}

.tech--scss {
  background: #be7dc9;
  color: #fff;
}

.tech--js {
  background: #ffe600;
  color: #000;
}

.tech--react {
  background: #4fb7fe;
  color: #fff;
}

.tech--css {
  background: #3b8eda;
  color: #fff;
}

.title-1 {
  font-family: var(--font-titles);
  font-weight: 700;
  line-height: 120%;
  color: var(--titles-color);
  font-size: 42px;
  font-size: clamp(2rem, 1.67rem + 1.39vw, 2.625rem);
}

.title-2 {
  font-family: var(--font-titles);
  font-weight: 700;
  font-size: 32px;
  line-height: 120%;
  color: var(--titles-color);
}

.webinar {
  border: 1px solid #c4dbec;
  border-radius: 10px;
  background: #fff;
}

.webinar__picture {
  padding: 10px;
  padding-bottom: 0;
}
.webinar__picture img {
  border-radius: 10px;
  width: 100%;
  height: auto;
  object-fit: cover;
}

.webinar__desc {
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.webinar__title {
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  color: #000;
}
@media (max-width: 670px) {
  .webinar__title {
    font-size: 16px;
  }
}

.webinar__text {
  margin-bottom: 30px;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #000;
}

.webinar__details {
  margin-top: auto;
  display: flex;
  column-gap: 15px;
  align-items: center;
}

/* No styles code below. Only in modules */
/* Не пишите CSS код ниже. Только в подключаемых файлах */