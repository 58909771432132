.nav {
	font-weight: 300;
	font-size: 16px;
	line-height: 130%;

	a {
		color: #000;
	}
}
.nav__list {
	display: flex;
	column-gap: 50px;
}

.active {
}
