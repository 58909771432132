.section-text {
	font-weight: 300;
	font-size: 16px;
	line-height: 160%;
	color: #000;

	text-wrap: balance;

	@include mobile {
		font-size: 14px;
	}

	p + p {
		margin-top: 0.5em;
	}
}
