.mobile-nav-overlay {
	position: fixed;
	display: block;
	top: 0;
	left: 0;
	z-index: 9;
	width: 100%;
	height: 100vh;
	background: rgba(37, 73, 116, 0.8);
	// cursor: pointer;

	pointer-events: none;
	opacity: 0;
	transition: all 0.2s ease-in;

	&--open {
		pointer-events: all;
		opacity: 1;
	}
}

.mobile-nav {
	position: fixed;
	// top: 0;
	top: 0;
	right: 0;
	width: 320px;
	height: 100%;
	z-index: 99;

	display: flex;
	flex-direction: column;
	// align-items: center;
	// justify-content: center;

	// padding: 40px 20px;
	// background: linear-gradient(209deg, #4287b3 -17.38%, #133f6b 78.4%), #276195;
	background: #fff;

	color: #000;

	transform: translate(100%, 0%);

	transition: all 0.2s ease-in;
}

.mobile-nav--open {
	transform: translate(0%, 0%);
}

.mobile-nav a {
	color: #000;
}

.mobile-nav__logo {
	// display: none;
	padding: 40px 20px 30px 30px;
	border-bottom: 1px solid #dcedf9;
}

.mobile-nav__nav {
	padding: 30px;
}

.mobile-nav__nav + .mobile-nav__nav {
	padding-top: 0;
}

.mobile-nav__title {
	margin-bottom: 20px;
	font-weight: 600;
	font-size: 16px;
	line-height: 130%;
	color: #698cb3;
}

.mobile-nav__list {
	display: flex;
	flex-direction: column;
	row-gap: 20px;

	font-weight: 300;

	li a {
		display: flex;
		align-items: center;
		column-gap: 10px;
	}

	strong {
		font-weight: 600;
	}
}

.mobile-nav__footer {
	margin-top: auto;
	border-top: 1px solid #dcedf9;
	background-color: #f6fbff;
}
