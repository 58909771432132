.intensive {
	border-radius: 10px;
	background: #f4faff;
}

.intensive__picture {
	padding: 10px;
	height: 240px;
	display: flex;
	justify-content: center;
	align-items: center;

	@include mobileMD {
		height: 220px;
	}
}

.intensive__desc {
	padding: 20px;

	display: flex;
	flex-direction: column;

	@include mobileMD {
		padding-top: 0;
	}
}

.intensive__title {
	margin-bottom: 10px;

	font-weight: 600;
	font-size: 18px;
	line-height: 140%;
	color: #000;

	@include mobileMD {
		font-size: 16px;
	}
}

.intensive__text {
	margin-bottom: 20px;

	font-weight: 400;
	font-size: 14px;
	line-height: 150%;
	color: #000;
}

.intensive__badges {
	margin-bottom: 30px;
}

.intensive__details {
	margin-top: auto;

	display: flex;
	column-gap: 15px;
	align-items: center;
}
