.plate {
	padding: 20px;
	border-radius: 12px;
	background: #d9ecfe;

	font-weight: 300;
	font-size: 14px;
	line-height: 130%;

	text-wrap: balance;

	@include mobile {
		padding: 16px;
		font-size: 12px;
	}
}

.plate__title {
	margin-bottom: 10px;

	font-weight: 700;
	font-size: 16px;
	line-height: 120%;
}

.plate--special {
	background: #fffaf0;
	// background: #deffc4, #fffaf0;

	@include mobile {
		background-color: #deffc4;
	}
}
