.tech {
	display: inline-block;
	border-radius: 3px;
	padding: 1px 5px;
	height: 20px;

	background: #7daac9;

	font-weight: 600;
	font-size: 12px;
	line-height: 150%;
	color: #fff;
}

.tech--scss {
	background: #be7dc9;
	color: #fff;
}

.tech--js {
	background: #ffe600;
	color: #000;
}

.tech--react {
	background: #4fb7fe;
	color: #fff;
}

.tech--css {
	background: #3b8eda;
	color: #fff;
}
