.laptop {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	// padding: 30px 30px;

	border-radius: 20px;
	// background-color: #fffaf0;

	font-weight: 400;
	font-size: 14px;
	line-height: 160%;
	text-align: center;
	color: #000;

	text-wrap: balance;

	@include mobile {
		// padding: 20px;
		// background-color: #deffc4;
	}

	a,
	button {
		text-decoration: underline;
		color: #000;
		// text-decoration-skip-ink: none;
	}

	small {
		font-size: 12px;
	}
}

.laptop__img-wrapper {
	position: relative;

	margin-bottom: 20px;
	flex-grow: 1;

	@include mobile {
		margin-bottom: 0;
	}
}

.laptop__btn {
	display: none !important;

	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);

	svg .path2 {
		transition: all 0.2s ease-in;

		&:hover {
			fill: red;
		}
	}
}

.laptop__img {
	border-radius: 20px;
	object-fit: cover;

	width: 100%;
	height: 100%;

	@include mobile {
		display: none;
	}
}

.laptop__content {
	width: 100%;
	padding: 20px;
	border-radius: 10px;
	// background-color: #deffc4;
	background-color: #fffaf0;

	@include mobile {
		// padding: 20px;
		background-color: #deffc4;
	}
}

.laptop__desc {
	margin-bottom: 10px;

	font-weight: 400;
	font-size: 14px;
	line-height: 160%;
	text-align: center;
	color: #6288b1;

	@include mobile {
		color: #547b35;
	}
}

.laptop__link {
}
