.section-title {
	font-weight: 700;
	font-size: 42px;
	font-size: clamp(1.75rem, 1.5rem + 1.25vw, 2.625rem);

	line-height: 130%;

	@include mobile {
		
		font-weight: 600;
	}

	a {
		color: inherit;
		text-decoration: underline;
	}
}
