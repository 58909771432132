.main {

}

.main__grid {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 30px;

	@include mobile {
		grid-template-columns: 1fr;
	}
}

.main__learn {
	grid-column: 1 / -1;
}
