.course {
	position: relative;

	padding: 10px;
	padding-right: 30px;

	display: flex;
	align-items: stretch;
	column-gap: 25px;

	border-radius: 16px;
	background-color: #fafafa;

	transition: all 0.2s ease-in;

	&:hover {
		background-color: #f3faff;
	}

	@include mobileMD {
		flex-direction: column;
		padding-right: 10px;
	}
}

.course__link {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
}

.course__picture {
	flex-shrink: 0;

	width: 330px;
	height: auto;
	border-radius: 16px;

	overflow: hidden;

	@include mobile {
		width: 200px;
	}

	@include mobileMD {
		width: 100%;

		img {
			width: 100%;
		}
	}

	img {
		object-fit: cover;
		height: 100%;
	}
}

.course__desc {
	padding-top: 20px;
	padding-bottom: 20px;

	display: flex;
	flex-direction: column;
	align-items: flex-start;
	row-gap: 10px;

	@include mobileMD {
		padding-bottom: 10px;
	}
}

.course__badges {
	display: flex;
	align-items: center;
	gap: 10px;
}

.course__title {
	font-weight: 600;
	font-size: 32px;
	font-size: clamp(1.375rem, 1.196rem + 0.89vw, 2rem);

	line-height: 130%;
}

.course__text {
	font-weight: 400;
	font-size: 14px;
	line-height: 150%;
	color: #000;

	p + p {
		margin-top: 10px;
	}
}

.course__details {
	display: flex;
	gap: 10px 20px;
	align-items: center;
	flex-wrap: wrap;
}
