.btn,
a.btn {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	column-gap: 10px;

	height: 45px;
	padding: 12px 30px;
	// height: 59px;

	border-radius: 8px;
	background: #000;

	font-weight: 600;
	font-size: 16px;
	line-height: 130%;
	text-align: center;
	color: #fff;

	transition: background-color 0.2s ease-in, color 0.2s ease-in;
}

.btn:hover,
.btn:focus {
	background-color: var(--accent);
}

.btn:active {
	background-color: #222222;
}
