.hero {
	--grad-1: linear-gradient(
		180deg,
		rgba(6, 31, 54, 0.5) 0%,
		rgba(9, 24, 39, 0.5) 100%
	);
	// Выше
	--grad-2: linear-gradient(
		180deg,
		rgba(0, 0, 0, 0) 0%,
		rgba(0, 0, 0, 0.5) 100%
	);

	height: 614px;
	height: clamp(18.75rem, 13.143rem + 28.04vw, 38.375rem);
	padding: 30px 0;

	background-color: #555555;
	background-image: var(--grad-2), var(--grad-1), url('./../../img/header/header-bg.jpg');
	background-position: center center; /* x y */
	background-size: cover;
	background-repeat: no-repeat;

	color: #fff;

	@include mediaBg() {
		background-image: var(--grad-2), var(--grad-1),
			url('./../../img/header/header-bg@2x.jpg');
	}

	.container {
		height: 100%;
	}
}

.hero__wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	height: 100%;

	text-align: center;
}

.hero__title {
	margin-bottom: 20px;

	font-weight: 700;
	// font-size: 72px;
	font-size: clamp(1.75rem, 0.964rem + 3.93vw, 4.5rem);

	line-height: 1;
	text-align: center;
}

.hero__desc {
	margin-bottom: 40px;
	max-width: 540px;

	font-weight: 300;
	font-size: 28px;
	font-size: clamp(1.125rem, 0.946rem + 0.89vw, 1.75rem);

	line-height: 1.6;
	text-align: center;

	text-wrap: balance;

	@include mobile {
		margin-bottom: 20px;
	}

	p + p {
		margin-top: 0.5em;
	}
}

.hero__arrow {
	margin-top: -15px;
	padding: 15px;

	@include mobile {
		margin-top: -5px;
		padding: 5px;
	}
}
