.webinar {
	border: 1px solid #c4dbec;
	border-radius: 10px;
	background: #fff;
}

.webinar__picture {
	padding: 10px;
	padding-bottom: 0;

	img {
		border-radius: 10px;

		width: 100%;
		height: auto;
		object-fit: cover;
	}
}

.webinar__desc {
	padding: 20px;

	display: flex;
	flex-direction: column;
}

.webinar__title {
	margin-bottom: 10px;

	font-weight: 600;
	font-size: 18px;
	line-height: 140%;
	color: #000;

	@include mobileMD {
		font-size: 16px;
	}
}

.webinar__text {
	margin-bottom: 30px;

	font-weight: 400;
	font-size: 14px;
	line-height: 150%;
	color: #000;
}

.webinar__details {
	margin-top: auto;

	display: flex;
	column-gap: 15px;
	align-items: center;
}
