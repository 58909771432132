.callback {
	padding: 60px 0 80px;
	background: #212121;
	color: #fff;

	@include mobile {
		padding: 30px 0 40px;
	}
}

.callback__title {
	margin-bottom: 10px;

	font-weight: 600;
	font-size: 28px;
	line-height: 130%;
}

.callback__desc {
	margin-bottom: 30px;

	font-weight: 400;
	font-size: 16px;
	line-height: 160%;
	color: rgba(255, 255, 255, 0.5);

	text-wrap: balance;

	@include mobile {
		font-size: 14px;
	}

	p + p {
		margin-top: 0.5em;
	}
}

.callback__form {
	display: flex;
	align-items: center;
	gap: 20px 30px;

	flex-wrap: wrap;

	@include mobile {
		column-gap: 15px;
	}

	@include mobileMD {
		flex-direction: column;
		align-items: stretch;

		& > * {
			width: 100%;
		}
	}
}

.callback__input {
	flex: 1;
	height: 58px;

	border-radius: 10px;
	background: #3e3e3e;

	padding: 18px 15px;

	font-weight: 400;
	font-size: 18px;
	line-height: 130%;
	color: #fff;

	@include mobile {
		font-size: 16px;
	}
}

.callback__btn {
	min-width: 290px;

	flex: 1;
	height: 58px;

	border-radius: 10px;
	background: #0060cf;

	font-weight: 600;
	font-size: 18px;
	text-align: center;
	color: #fff;

	@include mobile {
		font-size: 16px;
	}

	@include mobileMD {
		flex: auto;
	}
}

.callback__terms {
	padding-top: 10px;
	font-size: 12px;
	color: #777;

	a {
		color: #888;
		text-decoration: underline;
	}
}
