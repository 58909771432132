.post {
	display: flex;
	align-items: center;
	column-gap: 20px;

	padding-bottom: 20px;
	border-bottom: 1px solid #dcedf9;

	@include mobileMD {
		display: grid;
		grid-template-columns: auto auto;
		gap: 10px 20px;
	}

	&:last-child {
		border-bottom: none;
	}
}

.post__picture {
	flex-shrink: 0;

	overflow: hidden;
	border-radius: 10px;
	width: 60px;
	height: 60px;

	img {
		border-radius: 10px;
	}
}

.post__title {
	font-weight: 400;
	font-size: 16px;
	line-height: 130%;
	color: #000;

	max-width: 635px;

	@include mobileMD {
		font-size: 14px;
	}
}

.post__category {
	margin-left: auto;
	font-weight: 400;
	font-size: 16px;
	line-height: 130%;
	color: #7daac9;

	@include mobileMD {
		grid-column: 2 / -1;
		margin-left: 0;
		font-size: 14px;
	}
}
