.section {
	padding: 90px 0;
	border-bottom: 1px solid #dcedf9;

	@include tablet {
		padding: 60px 0;
	}

	@include mobile {
		padding: 30px 0;
	}
}

.section__badge {
	margin-bottom: 10px;
}

.section__title {
	margin-bottom: 15px;

	@include mobile {
		margin-bottom: 5px;
	}
}

.section__desc {
	margin-bottom: 30px;
	max-width: 730px;

	@include mobile {
		margin-bottom: 15px;
	}
}

.section__wrapper {
	&--courses {
		display: flex;
		flex-direction: column;
		row-gap: 30px;
	}

	// &--intesive {
	// 	display: flex;
	// 	gap: 30px;
	// 	flex-wrap: wrap;

	// 	& > * {
	// 		flex: 1;
	// 		// min-width: 340px;
	// 		min-width: 290px;
	// 	}
	// }

	&--intesive {
		display: grid;
		grid-template-columns: repeat(auto-fit,  minmax(290px, 1fr));
		gap: 30px;
	}

	&--blog {
		display: flex;
		flex-direction: column;
		row-gap: 20px;
	}
}
