.logo-mobile {
	display: inline-block;
}

.logo-mobile__img {
	margin-bottom: 2px;
	height: 26px;
}

.logo-mobile__text {
	display: block;

	font-weight: 400;
	font-size: 12px;
	line-height: 130%;
	color: #95b2d1;
}
