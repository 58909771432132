.free-course {
}

.free-course__cover {
	--grad-1: linear-gradient(
		180deg,
		rgba(5, 25, 43, 0.5) 0%,
		rgba(9, 24, 39, 0.5) 100%
	);
	--grad-2: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%);

	height: 395px;
	padding: 60px 0;

	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	color: #fff;

	background-color: #555;
	background-image: var(--grad-1), var(--grad-2),
		url('./../../img/free-course/free-course-bg.jpg');
	background-position: center center; /* x y */
	background-size: cover;
	background-repeat: no-repeat;

	border-radius: 16px;

	@include mediaBg() {
		background-image: var(--grad-1), var(--grad-2),
			url('./../../img/free-course/free-course-bg@2x.jpg');
	}
}

.free-course__badge {
	margin-bottom: 10px;
}

.free-course__title {
	font-weight: 600;
	font-size: 48px;
	font-size: clamp(1.75rem, 1.393rem + 1.79vw, 3rem);

	line-height: 1.3;
	text-align: center;
}

.free-course__content {
	position: relative;
	z-index: 1;

	margin: 0 auto;
	margin-top: -184px;
	padding: 30px;

	display: flex;
	flex-direction: column;
	align-items: center;
	row-gap: 20px;

	border-radius: 16px;
	max-width: 790px;
	height: 232px;

	box-shadow: 0 4px 25px 0 rgba(34, 55, 80, 0.15);
	background: #fff;

	color: #000;
	text-align: center;

	@include mobile {
		height: auto;
	}
}

.free-course__desc {
	font-weight: 400;
	font-size: 14px;
	line-height: 1.5;
	text-align: center;

	p + p {
		margin-top: 0.5em;
	}
}

.free-course__details {
	display: flex;
	gap: 10px 20px;
	justify-content: center;
	align-items: center;

	flex-wrap: wrap;
}

.free-course__btn {
}
